.title-purple {
    color: white;
    text-align: center;
    background-color: #61448c;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
}

.tittle-banner {
    text-align: center;
    color: white;
    background: url(../images/webinar/banner-home.png);
    background-size: cover;
    height: 100%;
}

.button-asset {
    height: 32px;
    width: 150px;
    background-size: cover;
    background: url(../images/webinar/boton.png);
}

.banner-bottom {
    color: #27203c;
    background-color: #ffffff;
    width: fit-content;
    border-radius: 6px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.webinar-body-row-register {
    color: white;
    background-color: #27203c;
}

.cursor-pointer {
    cursor: pointer;
}

.wave-container {
    color: white;
    background-color: #674492;
}

.card-box {
    font-size: small;
}

bold {
    font-weight: bold;
    margin-right: 3px;
}

.card-box .card-box-item {
    color: #1B1A31;
    font-size: xx-small;
}

.card-transparent {
    margin-bottom: 25px;
    padding: 10px;
    border-radius: 14px;
    color: white;
    background-color: #4d3570;
}

.body-end {
    color: white;
    background-color: #4d3570;
    border-radius: 0 0 30px 30px;
}

.webinar-partners {
    background-color: #ebebeb;
    color: black;
}

.max-720 {
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
}

.video-container {
    display: flex;
    justify-content: center;
}

iframe {
    aspect-ratio: 16 / 9;
    width: 100% !important;
}